export const firebaseConfig = {
    apiKey: "AIzaSyB9K-jIyjZxZZQDyrdHxnYAeRJI9s1kATs",
    authDomain: "talbot-racing-1cc23.firebaseapp.com",
    databaseURL: "https://talbot-racing-1cc23.firebaseio.com",
    projectId: "talbot-racing-1cc23",
    storageBucket: "talbot-racing-1cc23.appspot.com",
    messagingSenderId: "485647069400",
    appId: "1:485647069400:web:be3ce66365e96f5ccb67ad",
    measurementId: "G-1K1VW2X2GG",
  };

export const ClimacellKey = 'HGipO4JRfxaeQRcJONdgnj63SkZVFaoo';
