import React from 'react';

class HomePage extends React.PureComponent {

  render() {

    return (
      <>
        <p>this page is under construction</p>
      </>
    );
  }
}

export default HomePage;
